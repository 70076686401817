
import Vue from "vue"
import BaseItem from "../components/BItem.vue"
import axios from "axios"
import { dbSAB, sqlDatetime, variables } from "../plugins/variables"
import { Cuvier, Poste, Transfert } from "../store/types"

export default Vue.extend({
  name: "poste",
  components: { BaseItem },
  data() {
    return {
      dictionnaire: [] as any[],
      postes: [] as Poste[],
      finDePoste: false,
      sp_observation: "",
      unsubscribe: null as any
    }
  },
  methods: {
    initialiser() {
      axios
        .post(this.$store.state.ajaxurl + "ajax_appli.php", {
          action: "INITIALISER_POSTE"
        })
        .then(reponse => {
          this.postes = reponse.data.postes
        })
    },
    async envoyerALaBD() {
      const db = await dbSAB()
      const lesCuviers = (await db.getAll("sab_cuvier")).map((c: Cuvier) => {
        return new Cuvier(c)
      })
      const lesTransferts = (await db.getAll("sab_transfert")).map((t: Transfert) => {
        return new Transfert(t)
      })
      const machine_utiliser = await db.getAll("machine_utiliser")
      axios
        .post(this.$store.state.ajaxurl + "laitiers.php", {
          action: "ENVOYER_BD",
          lesCuviers: lesCuviers.filter((UnCuvier: Cuvier) => UnCuvier.getAEnvoyerALaBD()),
          lesTransferts: lesTransferts.filter((unTransfert: Transfert) => unTransfert.getAEnvoyerALaBD())
        })
        .then(() => {
          machine_utiliser.forEach(uneMachine => {
            db.delete("machine_utiliser", uneMachine.date)
          })
          db.close
        })
    },
    async quitter() {
      const db = await dbSAB()
      const co = await db.getAll("sab_co")
      axios
        .post(this.$store.state.ajaxurl + "ajax_appli.php", {
          action: "FIN_DE_POSTE",
          sp_id: co[0].sp_id,
          datetimeFin: sqlDatetime(new Date()),
          sp_observation: this.sp_observation
        })
        .then(() => {
          const store = db.transaction("sab_co", "readwrite").store
          store.clear()
          const store2 = db.transaction("sab_transfert", "readwrite").store
          store2.clear()
          const store3 = db.transaction("machine_utiliser", "readwrite").store
          store3.clear()
          const store4 = db.transaction("sab_posteActuel", "readwrite").store
          store4.clear()
          variables.authentifie = false
          this.$store.commit("majUtilisateur", "")
          this.$store.commit("majLangue", "AL")
          this.envoyerALaBD()
          this.$router.push("/login")
          this.finDePoste = false
          this.unsubscribe = []
        })
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = ["Fin de poste", "FIN DE POSTE", "Début", "Fin", "Salarié", "Observation", "Terminé", "Poste", "Matin", "Après-midi", "Nuit"]
          break
        case "AL":
          this.dictionnaire = ["Ende der schicht", "ENDE DER SHICHT", "Anfang", "Ende", "Angestellter", "Überwachung", "Beendet", "Arbeit", "Morgen", "Nachmittag", "Nacht"]
          break
      }
    },
    estSurQuellePoste(sp_poste: string) {
      switch (sp_poste) {
        case "m":
          return this.dictionnaire[8]
        case "p":
          return this.dictionnaire[9]
        case "n":
          return this.dictionnaire[10]
      }
    },
    trouverUtilisateur(ut_id: number) {
      const unSalarie = this.$store.state.salaries.find((s: any) => s.ut_id == ut_id)
      return unSalarie.ut_prenom + " " + unSalarie.ut_nom
    },
    datetimeFrancaise(dte: string) {
      if (dte === undefined || dte === null || dte === "") {
        return "..."
      } else {
        return dte.substr(8, 2) + "/" + dte.substr(5, 2) + " " + dte.substr(11, 5)
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixlangue(this.$store.state.lang)
          break
        case "majJour":
          this.initialiser()
          break
        case "majDeco":
          this.quitter()
      }
    })
  },
  mounted() {
    this.choixlangue(variables.langue)
    this.initialiser()
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
