
import Vue from "vue"

export default Vue.extend({
  name: "BaseItem",
  props: {
    color: { type: String, default: "grey lighten-3" },
    width: { type: String, default: "130px" },
    height: { type: String, default: "90px" }
  },
  methods: {
    // Prevent focus to match md spec
    click(e: Event) {
      this.$emit("click", e)
    }
  }
})
